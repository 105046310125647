/*
0-600px - phone
600-900px - tablet portrait
900-1200- tablet in landscape
1800 - big desktop

$breakpoint argument choices:
-phone
-tab-port
-tab-land
-big-desktop


ORDER: Base + Typography > general layout + grid> page layout > components

*/
@keyframes gallery-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes image-fade {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-decoration: none;
  outline: 0;
  border: 0; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding: 75px; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: white; }

::selection {
  background-color: #99A063;
  color: white; }

.container {
  position: relative;
  margin: 0 auto;
  width: 75%; }
  @media only screen and (min-width: 140em) {
    .container {
      width: 65%; } }
  @media only screen and (max-width: 56.25em) {
    .container {
      width: 80%; } }
  @media only screen and (max-width: 37.5em) {
    .container {
      width: 90%; } }

.full-page-container {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 90%; }
  @media only screen and (max-width: 37.5em) {
    .full-page-container {
      width: 90%; } }

.spacer {
  width: 100%;
  height: 4rem;
  background-color: transparent; }
  @media only screen and (max-width: 37.5em) {
    .spacer {
      height: 2rem; } }

.nav-spacer {
  height: 75px;
  width: 100%; }

.page-min-height {
  position: relative;
  min-height: 100vh; }

.universal-title {
  z-index: 1;
  line-height: 7rem;
  font-size: 5rem;
  color: #191919;
  font-weight: 800;
  position: relative;
  display: inline; }
  @media only screen and (max-width: 56.25em) {
    .universal-title {
      font-size: 4rem; } }
  .universal-title::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 6rem;
    height: 6rem;
    background-color: #99A063;
    right: 0;
    top: 0;
    border-radius: 50%;
    transform: translateY(-10%) translateX(20%); }
  .universal-title--plain::before {
    content: '';
    display: none; }
  .universal-title__container {
    width: 100%;
    display: flex;
    justify-content: center; }
  .universal-title--dot {
    display: none;
    color: #99A063;
    font-size: 6rem; }
  .universal-title--center {
    text-align: center; }
  .universal-title--fill {
    margin-left: 3rem;
    font-size: 3.5rem;
    font-weight: 500; }
    .universal-title--fill::before {
      display: none; }

.reveal {
  animation: reveal 0.7s ease-out backwards; }

@keyframes reveal {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translateY(100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    transform: translateY(0%); } }

.slide-reveal {
  position: relative;
  display: inline-block; }
  .slide-reveal::before {
    content: '';
    background-color: #99A063;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    animation: slide-reveal 0.7s forwards ease-in-out;
    backface-visibility: hidden;
    transform-origin: right; }

@keyframes slide-reveal {
  0% {
    transform: translate(-50%, -50%) scaleX(100%); }
  100% {
    transform: translate(-50%, -50%) scaleX(0); } }

.btn, .btn:link, .btn:visited {
  z-index: 1;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background-color: #99A063;
  color: white;
  padding: 1.4rem 3rem;
  display: inline-block;
  font-size: 1.7rem;
  position: relative;
  transition: all 0.85s;
  border-radius: 15rem; }

.btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: inherit;
  z-index: -1;
  border-radius: inherit;
  transition: all 0.85s; }

.btn:hover::before {
  opacity: 0;
  width: calc(100% + 3.2rem);
  height: calc(100% + 3.2rem); }

.btn__container--right {
  display: flex;
  justify-content: flex-end; }

.btn__form {
  transition: background-color 0.3s;
  margin-top: 2.5rem;
  display: block;
  text-align: center; }
  .btn__form__loading {
    background-color: #babf96;
    cursor: not-allowed; }
  .btn__form__loader {
    height: 1.5rem;
    position: absolute;
    top: 50%;
    right: 5rem;
    transform: translateY(-50%); }
    .btn__form__loader::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      animation: form-loading 1s infinite forwards; }
    .btn__form__loader::after {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      left: 0rem;
      top: 0; }

.water-btn {
  text-transform: uppercase;
  display: inline-block;
  padding: 1.5rem 2rem;
  font-weight: 800;
  letter-spacing: 0.5px;
  user-select: none;
  font-size: 1.7rem;
  position: relative;
  transition: color 0.3s, padding 0.3s, border-radius 0.3s;
  backface-visibility: hidden;
  overflow: hidden;
  cursor: pointer;
  color: #35d1f5; }
  .water-btn::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -2;
    transition-duration: 0.3s;
    backface-visibility: hidden;
    background-color: rgba(53, 209, 245, 0.15); }
  .water-btn::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    transition-duration: 0.3s;
    backface-visibility: hidden;
    background-color: #35d1f5; }
  .water-btn:hover {
    transition: color 0.3s 0.3s, padding 0.3s, border-radius 0.3s;
    border-radius: 4px;
    padding: 1.5rem 3rem;
    color: white; }
    .water-btn:hover::before {
      top: 0%; }
    .water-btn:hover::after {
      top: 0%;
      transition-delay: 0.3s; }
  .water-btn:active {
    padding: 1.5rem 4rem; }

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 17vw;
  grid-gap: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .gallery {
      grid-auto-rows: 20vw; } }
  @media only screen and (max-width: 37.5em) {
    .gallery {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 40vw; } }
  .gallery__figure {
    grid-row: auto/ span 1;
    grid-column: auto / span 1;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.4); }
    .gallery__figure:hover .gallery__figure__img {
      transform: scale(1.1); }
    .gallery__figure__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: all 0.5s;
      backface-visibility: hidden;
      position: relative; }

.cookie-box {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
  z-index: 10;
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  max-width: 35rem; }
  .cookie-box__text {
    line-height: 2.5rem;
    margin-bottom: 1rem;
    font-size: 1.4rem; }
  .cookie-box__link {
    color: #99A063;
    cursor: pointer; }
  .cookie-box__img {
    width: 50%;
    position: absolute;
    bottom: 90%;
    left: 0; }
  .cookie-box__btn-container {
    display: flex;
    justify-content: flex-start; }
    .cookie-box__btn-container__btn {
      font-size: 1.4rem; }

.input-container {
  position: relative;
  margin-top: 4.5rem; }
  .input-container__input {
    width: 100%;
    padding: 1.2rem 2rem;
    font-size: 1.7rem;
    border-radius: 15rem;
    border: 2px solid #99A063;
    transition: all 0.4s; }
    .input-container__input::placeholder {
      font-size: 1.7rem;
      color: #b3b3b3; }
    .input-container__input:hover {
      box-shadow: 0 0 3rem rgba(153, 160, 99, 0.3); }
    .input-container__input__textarea {
      resize: none;
      font-family: 'Open Sans', sans-serif;
      width: 100%;
      padding: 1.2rem 2rem;
      font-size: 1.7rem;
      border-radius: 5px;
      border: 2px solid #99A063;
      transition: all 0.4s; }
      .input-container__input__textarea::placeholder {
        font-size: 1.7rem;
        color: #b3b3b3; }
      .input-container__input__textarea:hover {
        box-shadow: 0 0 3rem rgba(153, 160, 99, 0.3); }
    .input-container__input__caption {
      font-size: 1.4rem;
      margin-top: 0.5rem; }
  .input-container__label {
    font-weight: 800;
    position: absolute;
    bottom: 100%;
    left: 0;
    font-size: 1.4rem;
    margin-bottom: 0.7rem; }

.radio-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.4rem; }
  @media only screen and (max-width: 37.5em) {
    .radio-container {
      grid-gap: 2rem; } }
  .radio-container__item__label {
    user-select: none;
    cursor: pointer;
    font-size: 1.7rem;
    position: relative;
    padding-left: calc(24px + 5px);
    display: inline-block; }
    .radio-container__item__label::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid #99A063; }
    .radio-container__item__label::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      transition: all 0.3s;
      transform: translateY(-50%) scale(0);
      width: 10px;
      height: 10px;
      background-color: #99A063;
      border-radius: 50%; }
  .radio-container__item__input {
    display: none; }
    .radio-container__item__input:checked + label:after {
      transform: translateY(-50%) scale(1); }

.checkbox-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.4rem; }
  @media only screen and (max-width: 37.5em) {
    .checkbox-container {
      grid-gap: 2rem; } }
  .checkbox-container__item__label {
    user-select: none;
    cursor: pointer;
    font-size: 1.7rem;
    position: relative;
    padding-left: calc(20px + 5px);
    display: inline-block; }
    .checkbox-container__item__label::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 3px solid #99A063; }
    .checkbox-container__item__label::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      transition: all 0.3s;
      transform: translateY(-50%) scale(0);
      width: 10px;
      height: 10px;
      background-color: #99A063;
      border-radius: 2px; }
  .checkbox-container__item__input {
    display: none; }
    .checkbox-container__item__input:checked + label:after {
      transform: translateY(-50%) scale(1); }

.modal {
  z-index: 40;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden; }
  .modal--visible {
    opacity: 1;
    visibility: visible; }
  .modal__close {
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    position: fixed;
    right: 1rem;
    top: 1rem; }
  .modal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s;
    background-color: rgba(0, 0, 0, 0.3); }
  .modal__content {
    position: absolute;
    top: 0;
    margin-top: 10rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
    padding: 4rem;
    z-index: 2;
    transition: all 0.4s;
    transform: translateY(60px);
    width: 45%; }
    @media only screen and (max-width: 56.25em) {
      .modal__content {
        width: 75%; } }
    @media only screen and (max-width: 37.5em) {
      .modal__content {
        width: 90%; } }
    .modal__content--visible {
      transform: translateY(0); }
    .modal__content__container {
      max-height: calc(100vh - 28rem);
      overflow: auto; }

#my-canvas {
  width: 100%;
  height: 100%; }

#my-canvas-parent {
  height: 60vh; }

.swiper-container {
  margin-top: 75px;
  height: calc(100vh - 75px); }
  .swiper-container--blog {
    margin-top: 0;
    height: initial; }

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  opacity: 1;
  background: #99A063 !important;
  opacity: 0.3 !important; }

.swiper-pagination-bullet-active {
  background-color: white !important;
  background: #99A063 !important;
  opacity: 1 !important; }

.swiper-button-prev::after {
  color: rgba(255, 255, 255, 0.9); }

.swiper-button-next::after {
  color: rgba(255, 255, 255, 0.9); }

.navigation {
  height: 75px;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
  z-index: 20;
  width: 100%;
  background: white;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0; }
  .navigation__list {
    list-style: none;
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 75em) {
      .navigation__list {
        width: 65%; } }
    @media only screen and (max-width: 56.25em) {
      .navigation__list {
        width: 85%; } }
    @media only screen and (max-width: 37.5em) {
      .navigation__list {
        width: 95%; } }
    .navigation__list__panel {
      width: 75%; }
    .navigation__list__item {
      padding: 1.2rem;
      cursor: pointer;
      color: #191919; }
      .navigation__list__item:hover {
        color: #99A063; }
      .navigation__list__item__link {
        font-size: 1.7rem;
        color: inherit;
        transition: color 0.2s; }
        @media only screen and (max-width: 56.25em) {
          .navigation__list__item__link {
            padding: 2rem 6rem;
            display: block; } }
      .navigation__list__item__img {
        margin-right: auto;
        padding: 0.2rem 0;
        position: relative;
        height: 100%; }
        .navigation__list__item__img__icon {
          height: 100%; }
      @media only screen and (max-width: 56.25em) {
        .navigation__list__item {
          padding: 0;
          width: 100%; }
          .navigation__list__item:not(:last-child) {
            border-bottom: 1px solid whitesmoke; } }
    .navigation__list__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.55s; }
      @media only screen and (max-width: 56.25em) {
        .navigation__list__container {
          width: initial;
          min-width: 30%;
          height: 100%;
          background-color: white;
          position: fixed;
          top: 75px;
          left: 0;
          transform: translateX(-100%);
          display: flex;
          flex-direction: column;
          justify-content: flex-start; } }
    .navigation__list__wider {
      width: 85%; }
  .navigation__background {
    transition: all 0.5s;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    visibility: hidden;
    top: 75px;
    transform: translateX(-100%); }

.stripes {
  cursor: pointer;
  width: 75px;
  height: 75px;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  justify-content: center; }
  @media only screen and (max-width: 56.25em) {
    .stripes {
      display: flex; } }
  .stripes__checkbox {
    display: none; }
  .stripes__one {
    backface-visibility: hidden;
    width: calc(55px * 0.4);
    height: 1px;
    background-color: #99A063;
    position: absolute;
    top: 42%;
    transform: translateY(-50%) rotate(0);
    transition: transform 0.25s, top 0.1s 0.15s cubic-bezier(0.6, 0.1, 0.68, 0.92); }
  .stripes__two {
    backface-visibility: hidden;
    width: calc(55px * 0.4);
    height: 1px;
    background-color: #99A063;
    position: absolute;
    bottom: 42%;
    transform: translateY(50%) rotate(0);
    transition: transform 0.25s, bottom 0.1s 0.15s cubic-bezier(0.6, 0.1, 0.68, 0.92); }

.stripes__checkbox:checked + div + ul + label div div:nth-of-type(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: transform 0.25s 0.1s, top 0.1s cubic-bezier(0.6, 0.1, 0.68, 0.92); }

.stripes__checkbox:checked + div + ul + label div div:nth-of-type(2) {
  bottom: 50%;
  transform: translateY(50%) rotate(-45deg);
  transition: transform 0.25s 0.1s, bottom 0.1s cubic-bezier(0.6, 0.1, 0.68, 0.92); }

@media only screen and (max-width: 56.25em) {
  .stripes__checkbox:checked + div + ul div {
    transform: translateX(0%); } }

.stripes__checkbox:checked + div {
  visibility: visible;
  opacity: 1;
  transform: translateX(0); }

.footer {
  padding: 4rem 0;
  width: 100%;
  background-color: #191919; }
  .footer__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer__item, .footer__item:link, .footer__item:visited {
    color: #525252;
    text-decoration: none;
    border: none;
    padding: 2rem;
    font-size: 1.7rem;
    line-height: 3.3rem; }
  .footer__item__info--white {
    color: white; }
  .footer__item__info__title {
    color: #99A063;
    font-weight: 800; }
  .footer__item__info__socials-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around; }
    .footer__item__info__socials-container__item {
      cursor: pointer;
      height: 5.5rem;
      transition: all 0.4s; }
      .footer__item__info__socials-container__item:hover {
        transform: scale(0.9); }
  .footer__item__link {
    color: #525252;
    transition: all 0.3s; }
    .footer__item__link:hover {
      color: #99A063; }

.section-jumbo {
  width: 100%;
  position: relative;
  min-height: calc(100vh - 75px);
  overflow: hidden; }
  @media only screen and (max-width: 56.25em) {
    .section-jumbo {
      min-height: initial; } }
  .section-jumbo__container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center; }
    @media only screen and (max-width: 56.25em) {
      .section-jumbo__container {
        grid-template-columns: 1fr; } }
    @media only screen and (max-width: 75em) {
      .section-jumbo__container__products {
        grid-template-columns: 1fr; } }
    .section-jumbo__container__full {
      grid-column: 1/3;
      width: 100%; }
      @media only screen and (max-width: 75em) {
        .section-jumbo__container__full {
          width: 90%;
          margin: 0 auto; } }
      .section-jumbo__container__full__white-bg-title {
        box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 4rem;
        background-color: rgba(255, 255, 255, 0.8); }
    .section-jumbo__container__left {
      min-height: calc(100vh - 75px);
      grid-column: 1/2; }
      @media only screen and (max-width: 56.25em) {
        .section-jumbo__container__left {
          min-height: initial;
          grid-column: 1/3; } }
    .section-jumbo__container__right {
      grid-column: 2/3; }
      @media only screen and (max-width: 56.25em) {
        .section-jumbo__container__right {
          grid-column: 1/3; } }
      .section-jumbo__container__right__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 0 auto;
        width: 100%;
        box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin-top: 4rem;
        margin-bottom: 4rem;
        padding: 4rem;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%; }
        @media only screen and (max-width: 75em) {
          .section-jumbo__container__right__content {
            width: 90%;
            grid-column: 1/3; } }
        .section-jumbo__container__right__content__title {
          z-index: 1; }
        .section-jumbo__container__right__content__text {
          text-align: justify;
          font-size: 2rem;
          line-height: 3.3rem; }
  .section-jumbo__background {
    width: 90%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    min-height: 100vh; }
    .section-jumbo__background__figure {
      grid-row: auto/ span 1;
      grid-column: auto / span 1;
      overflow: hidden;
      position: relative; }
      .section-jumbo__background__figure__img {
        user-select: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        backface-visibility: hidden;
        position: relative; }

.section-slider {
  width: 90%;
  margin: 0 auto;
  position: relative; }
  .section-slider__right {
    pointer-events: none;
    user-select: none;
    width: 50%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 75em) {
      .section-slider__right {
        width: 100%; } }
    .section-slider__right__content {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center; }
      .section-slider__right__content__img {
        width: 30%; }
        @media only screen and (max-width: 37.5em) {
          .section-slider__right__content__img {
            width: 50%; } }
      .section-slider__right__content__text {
        text-align: center;
        color: white;
        font-family: 'Allura', cursive;
        font-size: 4.5rem;
        letter-spacing: 2px; }
  .section-slider__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; }
    .section-slider__container__figure {
      grid-row: auto/ span 1;
      grid-column: auto / span 1;
      overflow: hidden;
      position: relative; }
      .section-slider__container__figure__img {
        user-select: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        backface-visibility: hidden;
        position: relative;
        animation: image-fade 1.4s ease-out; }

.blog-section {
  position: relative;
  width: 100%;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr; }
  @media only screen and (max-width: 75em) {
    .blog-section {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (max-width: 37.5em) {
    .blog-section {
      grid-template-columns: 1fr; } }
  .blog-section__post {
    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    padding: 3rem; }
    .blog-section__post__preview {
      padding-top: 2rem;
      font-size: 1.7rem;
      line-height: 3.3rem;
      margin-bottom: 2rem;
      margin-top: 14vw; }
      @media only screen and (max-width: 75em) {
        .blog-section__post__preview {
          margin-top: 19vw; } }
      @media only screen and (max-width: 56.25em) {
        .blog-section__post__preview {
          margin-top: 22vw; } }
      @media only screen and (max-width: 37.5em) {
        .blog-section__post__preview {
          margin-top: 48vw; } }
    .blog-section__post__gallery {
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.45);
      left: 3rem;
      top: 3rem;
      position: absolute;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 14vw;
      width: 90%;
      transform: translateX(-50%);
      left: 50%; }
      @media only screen and (max-width: 75em) {
        .blog-section__post__gallery {
          grid-auto-rows: 19vw; } }
      @media only screen and (max-width: 56.25em) {
        .blog-section__post__gallery {
          grid-auto-rows: 22vw; } }
      @media only screen and (max-width: 37.5em) {
        .blog-section__post__gallery {
          width: 90%;
          transform: translateX(-50%);
          left: 50%;
          grid-auto-rows: 48vw; } }
      .blog-section__post__gallery__figure {
        border-radius: 4px;
        grid-row: auto/ span 1;
        grid-column: auto / span 1;
        overflow: hidden;
        position: relative; }
        .blog-section__post__gallery__figure__img {
          transition: all 0.5s;
          user-select: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          backface-visibility: hidden;
          position: relative; }
        .blog-section__post__gallery__figure:hover .blog-section__post__gallery__figure__img {
          transform: scale(1.1); }

.products__container {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr; }
  @media only screen and (max-width: 37.5em) {
    .products__container {
      grid-template-columns: 1fr; } }
  .products__container__item {
    cursor: pointer;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    padding: 2rem;
    border-radius: 6px; }
    @media only screen and (max-width: 56.25em) {
      .products__container__item {
        padding: 1rem; } }
    .products__container__item:hover {
      box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25); }
    .products__container__item:active {
      transform: scale(0.9); }
    .products__container__item__img {
      margin-right: 2rem;
      height: 8rem; }
      @media only screen and (max-width: 56.25em) {
        .products__container__item__img {
          height: 7rem; } }
    .products__container__item__text {
      color: black;
      font-size: 2rem; }
      @media only screen and (max-width: 56.25em) {
        .products__container__item__text {
          font-size: 2rem; } }

.products__text {
  font-size: 2rem;
  line-height: 3.3rem; }
  .products__text__bold {
    color: #99A063;
    font-weight: 800;
    font-size: 3rem; }

.products__gallery {
  margin-left: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 25vw; }
  @media only screen and (max-width: 56.25em) {
    .products__gallery {
      grid-auto-rows: 40vw;
      margin-left: 0; } }
  .products__gallery__figure {
    border-radius: 4px;
    grid-row: 1/2;
    grid-column: 1/2;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transition: opacity 0.7s; }
    .products__gallery__figure__visible {
      opacity: 1; }
    .products__gallery__figure__img {
      transition: all 0.5s;
      user-select: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      backface-visibility: hidden;
      position: relative; }

.error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end; }
  .error__info {
    color: #191919;
    font-size: 2.2vw;
    align-self: flex-start; }
    @media only screen and (max-width: 56.25em) {
      .error__info {
        font-size: 5vw; } }
    @media only screen and (max-width: 37.5em) {
      .error__info {
        font-size: 5.5vw; } }
  .error__code {
    margin: 3rem 0;
    align-self: center;
    font-size: 15vw;
    color: #e7e7e7; }
    @media only screen and (max-width: 56.25em) {
      .error__code {
        font-size: 22vw; } }
    @media only screen and (max-width: 37.5em) {
      .error__code {
        font-size: 28vw; } }
  .error__container {
    display: flex;
    align-items: center; }

.privacy__link-container {
  display: flex;
  justify-content: center; }

.privacy__container {
  padding: 4rem;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
  border-radius: 5px; }

.privacy-header {
  line-height: 3.3rem;
  font-size: 2.4rem;
  font-weight: 800;
  margin-bottom: 2rem;
  color: #191919; }
  .privacy-header:not(:first-child) {
    margin-top: 4rem; }

.privacy__text {
  font-size: 1.7rem;
  line-height: 3.3rem;
  font-weight: 400;
  color: #191919; }

.section-product__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center; }
  .section-product__container__item {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .section-product__container__item__img {
      height: 18rem; }
    .section-product__container__item__logo {
      height: 18rem; }

.production-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 3rem;
  margin-top: 3rem; }
  @media only screen and (max-width: 75em) {
    .production-container {
      grid-template-columns: 1fr; } }
  .production-container__left {
    grid-column: 1/3; }
    @media only screen and (max-width: 75em) {
      .production-container__left {
        grid-row: 1/span 1;
        grid-column: 1/-1; } }
  .production-container__right {
    grid-column: 3/6; }
    @media only screen and (max-width: 75em) {
      .production-container__right {
        grid-column: 1/-1; } }
  .production-container__card {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 4rem; }
    .production-container__card__item:not(:last-child) {
      margin-bottom: 2rem; }
    .production-container__card__item__gallery {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 28vw; }
      @media only screen and (max-width: 75em) {
        .production-container__card__item__gallery {
          grid-auto-rows: 48vw; } }
      @media only screen and (max-width: 56.25em) {
        .production-container__card__item__gallery {
          grid-auto-rows: 48vw; } }
      .production-container__card__item__gallery__figure {
        border-radius: 4px;
        grid-row: auto/ span 1;
        grid-column: auto / span 1;
        overflow: hidden;
        position: relative; }
        .production-container__card__item__gallery__figure__img {
          transition: all 0.5s;
          user-select: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          backface-visibility: hidden;
          position: relative; }
        .production-container__card__item__gallery__figure:hover .production-container__card__item__gallery__figure__img {
          transform: scale(1.05); }
    .production-container__card__item__text {
      user-select: none;
      cursor: pointer;
      color: black;
      font-size: 1.7rem;
      line-height: 3.3rem;
      padding: 1rem;
      transition: all 0.3s;
      border-radius: 4px;
      backface-visibility: hidden; }
      .production-container__card__item__text:hover {
        box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.25); }
      .production-container__card__item__text:active {
        transform: scale(0.95); }
      .production-container__card__item__text--bullet-point {
        padding-left: 5rem;
        font-weight: 800;
        font-size: 1.7rem;
        line-height: 3.3rem;
        position: relative; }
        .production-container__card__item__text--bullet-point::before {
          display: none;
          content: '';
          position: absolute;
          left: 1rem;
          top: 50%;
          transform: translateY(-50%);
          width: 3rem;
          height: 3rem;
          background-image: url("/resources/images/bullet.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }

.btn-facebook-share {
  text-align: center;
  font-size: 1.7rem;
  line-height: 2.5rem;
  background-color: #1877f2;
  color: white;
  padding: 1rem 2rem;
  padding-left: calc(3.5rem + 0.7rem);
  border-radius: 4px;
  cursor: pointer;
  position: relative; }
  .btn-facebook-share::before {
    transition: all 0.4s;
    content: '';
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    background-image: url("/resources/icons/icon-fb-plain.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }

.product-option-display__hidden {
  display: none;
  pointer-events: none; }

.product-option__active::before {
  display: initial; }

.filter-section__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .filter-section__container__item {
    font-size: 1.7rem;
    line-height: 3.3rem;
    color: black;
    padding: 1rem; }
    .filter-section__container__item__label {
      font-weight: 800;
      user-select: none;
      cursor: pointer;
      font-size: 2rem;
      position: relative;
      display: inline-block; }
      .filter-section__container__item__label--active {
        color: #99A063; }
      .filter-section__container__item__label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: 3px solid #99A063;
        display: none; }
      .filter-section__container__item__label::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 50%;
        transition: all 0.3s;
        transform: translateY(-50%) scale(0);
        width: 10px;
        height: 10px;
        background-color: #99A063;
        border-radius: 2px;
        display: none; }
    .filter-section__container__item__input {
      display: none; }
      .filter-section__container__item__input:checked + label:after {
        transform: translateY(-50%) scale(1); }

.post-slide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4rem; }
  .post-slide__item__text {
    font-size: 1.7rem;
    color: black;
    line-height: 3.3rem;
    padding: 2rem; }
    .post-slide__item__text--private {
      padding: 0; }
      .post-slide__item__text--private:not(:last-child) {
        margin-bottom: 3rem; }
  .post-slide__item__gallery {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 28vw; }
    .post-slide__item__gallery:not(:last-child) {
      margin-bottom: 3rem; }
    @media only screen and (max-width: 75em) {
      .post-slide__item__gallery {
        grid-auto-rows: 48vw; } }
    @media only screen and (max-width: 56.25em) {
      .post-slide__item__gallery {
        grid-auto-rows: 48vw; } }
    .post-slide__item__gallery__figure {
      grid-row: auto/ span 1;
      grid-column: auto / span 1;
      overflow: hidden;
      position: relative; }
      .post-slide__item__gallery__figure__img {
        transition: all 0.5s;
        user-select: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        backface-visibility: hidden;
        position: relative; }
      .post-slide__item__gallery__figure:hover .post-slide__item__gallery__figure__img {
        transform: scale(1.05); }
      .post-slide__item__gallery__figure__caption {
        font-size: 1.7rem;
        line-height: 3.3rem;
        color: black;
        padding: 2rem;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% - 4rem);
        text-align: right; }

.private-post-section__title {
  font-weight: 800;
  font-size: 2.4rem; }
  .private-post-section__title__date {
    color: #99A063;
    font-size: 1.7rem; }

.section-gallery__nav {
  display: flex; }
  .section-gallery__nav__container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center; }
  .section-gallery__nav__item__checkbox {
    display: none; }
  .section-gallery__nav__item__label {
    cursor: pointer;
    display: inline-block;
    margin: 0 4px;
    background-color: #99A063;
    opacity: 0.3;
    border-radius: 50%;
    width: 15px;
    height: 15px; }
    .section-gallery__nav__item__label--active {
      opacity: 1; }

.section-gallery__gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 16vw;
  grid-gap: 3rem; }
  @media only screen and (max-width: 75em) {
    .section-gallery__gallery {
      grid-auto-rows: 20vw; } }
  @media only screen and (max-width: 56.25em) {
    .section-gallery__gallery {
      grid-gap: 1rem;
      grid-auto-rows: 20vw; } }
  @media only screen and (max-width: 37.5em) {
    .section-gallery__gallery {
      grid-auto-rows: 28vw; } }
  .section-gallery__gallery__figure:nth-child(5n+1) {
    grid-column-start: 3;
    grid-column-end: 7; }
    @media only screen and (max-width: 56.25em) {
      .section-gallery__gallery__figure:nth-child(5n+1) {
        grid-column-start: initial;
        grid-column-end: initial;
        grid-column-start: span 6; } }
  .section-gallery__gallery__figure:nth-child(5n+2) {
    grid-column-start: 7;
    grid-column-end: 11; }
    @media only screen and (max-width: 56.25em) {
      .section-gallery__gallery__figure:nth-child(5n+2) {
        grid-column-start: initial;
        grid-column-end: initial;
        grid-column-start: span 6; } }
  .section-gallery__gallery__figure {
    grid-column: span 4;
    overflow: hidden;
    position: relative;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.4); }
    @media only screen and (max-width: 37.5em) {
      .section-gallery__gallery__figure {
        grid-column: span 6; } }
    .section-gallery__gallery__figure__img {
      transition: all 0.5s;
      user-select: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      backface-visibility: hidden;
      position: relative; }
    .section-gallery__gallery__figure:hover .section-gallery__gallery__figure__img {
      transform: scale(1.05); }

.cat-item__visible {
  visibility: visible;
  display: block; }
